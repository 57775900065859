export class AppSettings {
   public static API_ENDPOINT    = 'https://backend.skyscrapersnow.com/apiblogs/';
   // public static API_ENDPOINT    = 'http://192.168.19.52:8000/apiblogs/';
   public static IMAGE_URL_TEMP  = 'https://backend.skyscrapersnow.com/uploads/blogs/temp/';
   // public static IMAGE_URL_TEMP  = 'http://192.168.19.52:8000/uploads/blogs/temp/';
   public static IMAGE_URL       = 'https://academic-sites-uploads.s3.us-east-1.amazonaws.com/';
   public static PROJECT_ID      = 1;

   public static blogStatus         = ['Pending', 'Active', 'Draft'];
   public static verifiedStatuses   = [{'name': 'Pending', 'value': 0}, {'name': 'Approved', 'value': 1}];

   public static cities  = [
    {"id": 11, "name" : "Islamabad"},
    {"id": 5,  "name" : "Rawalpindi"}
   ];

   public static sitesList  = [
    { name : "MyPerfectWords",              url: "myperfectwords.com",                 stagingUrl : "mpw.collegeessay.org"},
    { name : "College Essay",               url: "collegeessay.org",                   stagingUrl : "staging.collegeessay.org"},
    { name : "Essay Writing Service",       url: "www.essaywritingservice.college",    stagingUrl : "essaywritingservicecollege.collegeessay.org"},
    { name : "Write My Essay Help",         url: "www.writemyessay.help",              stagingUrl : "writemyessayhelp.collegeessay.org"},
    { name : "Essay Writer College",        url: "www.essaywriter.college",            stagingUrl : "essaywritercollege.collegeessay.org"},
    { name : "Shark Papers",                url: "www.sharkpapers.com",                stagingUrl : "sharkpapers.collegeessay.org"},
    { name : "Essay Hours",                 url: "www.essayhours.com",                 stagingUrl : "essayhours.collegeessay.org"},
    { name : "My Perfect Paper",            url: "www.myperfectpaper.net",             stagingUrl : "mpp.collegeessay.org"},
    { name : "Essay Writer Now",            url: "www.essaywriternow.com",             stagingUrl : "essaywriternow.collegeessay.org"},
    { name : "5 Stars Essays",              url: "www.5staressays.com",                stagingUrl : "www.5staressays.com"},
    { name : "The Essay Writing Service",   url: "theessaywritingservice.com",         stagingUrl : "theessaywritingservice.collegeessay.org"},
    { name : "Your Essay Writer",           url: "www.youressaywriter.net",            stagingUrl : "youressaywriter.collegeessay.org"},
    { name : "Write My Essay Fast",         url: "www.writemyessayfast.net",           stagingUrl : "writemyessayfast.collegeessay.org"},
    { name : "Grad School Genius",          url: "www.gradschoolgenius.com",           stagingUrl : "www.gradschoolgenius.com"},
    { name : "Essay Writer For Me",         url: "www.essaywriterforme.com",           stagingUrl : "www.essaywriterforme.com"},
    { name : "Free Essay Writer Net",       url: "www.freeessaywriter.net",            stagingUrl : "few.collegeessay.org"},
    { name : "Perfect Essay Writer",        url: "www.perfectessaywriter.ai",          stagingUrl : "staging.perfectessaywriter.ai"},
    { name : "Perfect Essay Writer Blogs",  url: "perfectessaywriter.ai",          stagingUrl : "staging.perfectessaywriter.ai"},
    { name : "My Essay Writer",             url: "www.myessaywriter.ai",               stagingUrl : "myessaywriter.collegeessay.org"},
    { name : "Essay Service",               url: "www.essayservice.ai",                stagingUrl : "staging.essayservice.ai"},
   ];

   public static sitesName = [
     "MyPerfectWords", "College Essay"
   ];


   public static pageTypes  = [
    { name : "Article Page", val: "service", route: '/services'  },
    { name : "Blog Page",    val: "blog",    route: '/blogs'     },
    { name : "Guide Page",    val: "guide",    route: '/guides'     }
   ];

   public static serviceTypes  = [
    { name : "New Article Page",         val: "parent"    },
    { name : "Existing Article Page",    val: "child"     }
   ];

   public static blogTypes  = [
    { name : "New Blog Page",            val: "parent"    },
    { name : "Existing Blog Page",       val: "child"     }
   ];
   public static guideTypes  = [
    { name : "New Guide Page",            val: "parent"    },
    { name : "Existing Guide Page",       val: "child"     }
   ];

   public static pageStyles  = [
    { name : "Article Style Service Page",     val: "article-style", route: '/article'   },
    // { name : "Standard Service Page",          val: "standard-style", route: '/article'   }
   ];

   public static blogStyles  = [
    { name : "Template 1",     val: "template-1", route: '/blog' },
    { name : "Template 2",     val: "template-2", route: '/blog' },
   ];
   public static guideStyles  = [
    { name : "Template 1",     val: "template-1", route: '/guide' },
    { name : "Template 2",     val: "template-2", route: '/guide' },
   ];

   public static roles = ["Admin","Proof Reader", "Blogger"];

   public static expertise = [
     "Science","English","Maths"
   ];

   public static snippetTypes = [
    'PDF','Advertisment','Expert Tips', 'Colored Box'
  ];

  public static slotHoursArr24       =
                      [
                       {val: '00' ,name:'12 AM'},
                       {val: '01' ,name:'01 AM'},
                       {val: '02' ,name:'02 AM'},
                       {val: '03' ,name:'03 AM'},
                       {val: '04' ,name:'04 AM'},
                       {val: '05' ,name:'05 AM'},
                       {val: '06' ,name:'06 AM'},
                       {val: '07' ,name:'07 AM'},
                       {val: '08' ,name:'08 AM'},
                       {val: '09' ,name:'09 AM'},
                       {val: '10' ,name:'10 AM'},
                       {val: '11' ,name:'11 AM'},
                       {val: '12' ,name:'12 PM'},
                       {val: '13' ,name:'01 PM'},
                       {val: '14' ,name:'02 PM'},
                       {val: '15' ,name:'03 PM'},
                       {val: '16' ,name:'04 PM'},
                       {val: '17' ,name:'05 PM'},
                       {val: '18' ,name:'06 PM'},
                       {val: '19' ,name:'07 PM'},
                       {val: '20' ,name:'08 PM'},
                       {val: '21' ,name:'09 PM'},
                       {val: '22' ,name:'10 PM'},
                       {val: '23' ,name:'11 PM'},

                     ];

  public static blogCategories = ['How to Write', 'Essay Guides', 'Essay Examples', 'Essay Topics', 'Essay Outline Guides', 'Citations and Formatting Guides', 
                                  'Research Paper Writing Guides', 'Thesis Writing Guides', 'Speech & Debate Writing Guides', 'Literary Writing Guides', 
                                  'General Guides', 'Universities and Colleges' , 'Reviews' , 'Lifetyle'];
  public static guideCategories = ['Argumentative Essay','Descriptive Essay','Narrative Essay','Persuasive Essay','Expository Essay','Analytical Essay','How to Write an Essay','Thesis Writing','How To Write An Annotated Bibliography','How To Write A Personal Statement','How To Write A Thesis','How To Write A Book Report','APA Format','How To Write A Case Study'];
}
