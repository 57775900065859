import { Injectable } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettings } from './app.setting';

declare var config: any;

import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject : BehaviorSubject<User>;
    // public  currentUser        : Observable<any>;
    public  currentUser        : any;
    public  corsHeaders        : any;

    private currentUserS: BehaviorSubject<any> = new BehaviorSubject<any>(this.currentUser);
    currentUserS$: Observable<any> = this.currentUserS.asObservable();

    constructor(private http: HttpClient) {
      if (JSON.parse(localStorage.getItem('currentUser'))) {

        if(typeof JSON.parse(localStorage.getItem('currentUser'))['sessionTimeout'] == "undefined") {
            this.simpleLogout();
        } else {
            this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['user'];
            this.currentUserS.next(this.currentUser);

            //check if past expiration date
            let sessionTimeout = JSON.parse(localStorage.getItem('currentUser'))['sessionTimeout'];
            if (new Date(sessionTimeout) < new Date()) {
              this.simpleLogout();
            }
        }
      }
    }

    public get_currentUserValue(): any {
        return this.currentUser;
    }

    public setCurrentUserValue(user): any {
      let data         = {};
          data['user'] = user;
      let timeout      = new Date();
      timeout.setHours(timeout.getHours() + 12); // 12 hrs of session
      data['sessionTimeout'] = timeout.toString();
      try {
        localStorage.setItem('currentUser', JSON.stringify(data));
        this.currentUser = user;
        this.currentUserS.next(this.currentUser);
      }
      catch (e) {

      }
    }

    login(username, password) {
        return this.http.post<any>(`${config.apiUrl}/users/authenticate`, { username, password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                return user;
            }));
    }

    simpleLogout() {
      // Remove From Session
      localStorage.removeItem('currentUser');
      this.currentUser = null;
      this.currentUserS.next(this.currentUser);
    }

    logout(): Promise<any> {
      var corsHeaders = {
        headers: new HttpHeaders()
          .set('Content-Type',  'application/json')
          .set('Accept',  'application/json')
          .set('Authorization',  `Bearer ${this.currentUser['token']}`)
      }
      this.corsHeaders = corsHeaders;

      // Remove From Session
      localStorage.removeItem('currentUser');
      this.currentUser = null;
      this.currentUserS.next(this.currentUser);

      let url = AppSettings.API_ENDPOINT + 'auth/logout';

      return this.http.get(url, this.corsHeaders).toPromise()
          .then( resp => {
              // remove user from local storage and set current user to null
              // localStorage.removeItem('currentUser');
              // this.currentUser = null;

              return true;
          })
          .catch(this.handleErrorPromise);
    }

    handleErrorPromise (error: Response | any) {
      console.log("Error: ", error);
      console.log("Error: ", error.message);
      return Promise.reject(error.error);
    }
}
